html {
  overflow-y: hidden;
  box-sizing: border-box;
  font-size: 16px;
}

body {
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

@font-face {
  font-family: ProximaNova;
  src: url(./assets/ProximaNova-Regular.otf);
}

.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  font-family: 'ProximaNova';

}

.buttonShare {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  width: 50%;
  cursor: pointer;
  outline: none;
}

/* @media (max-width: 1024px) {
  .App {
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}*/
